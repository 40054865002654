import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img alt="Deploy" src="https://github.com/sigi-framework/documents/workflows/Deploy/badge.svg" />{`
`}<a parentName="p" {...{
        "href": "https://codecov.io/gh/sigi-framework/sigi"
      }}><img alt="codecov" src="https://codecov.io/gh/sigi-framework/sigi/branch/master/graph/badge.svg" /></a>{`
`}<a parentName="p" {...{
        "href": "https://badge.fury.io/js/%40sigi%2Fcore"
      }}><img alt="npm version" src="https://badge.fury.io/js/%40sigi%2Fcore.svg" /></a>{`
`}<a parentName="p" {...{
        "href": "https://join.slack.com/t/sigiframework/shared_invite/enQtOTM4ODYwMjQ5OTY5LTRmM2JhYTA1NTA5OWVkZWQzZmVkZjE4OGM0ZjM4NGJlZTliNTZjYjE5OGExMjE3MTNkNGE2NmE1MjM5NDQwNTY"
      }}><img alt="Slack Status" src="https://img.shields.io/badge/slack-@sigi/framework-green.svg?logo=slack" /></a></p>
    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>
    <p><strong parentName="p">{`Sigi`}</strong>{` is a high level `}<strong parentName="p">{`Effect Manager`}</strong>{` with well designed API based on `}<inlineCode parentName="p">{`RxJS`}</inlineCode>{` and `}<inlineCode parentName="p">{`Immer`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`TypeScript`}</inlineCode>{` is not required but `}<strong parentName="p">{`strongly recommended`}</strong>{`.`}</p>
    <h2 {...{
      "id": "features"
    }}>{`Features`}</h2>
    <ul>
      <li parentName="ul">{`🌊 `}<strong parentName="li">{`Stream`}</strong>{`: thanks to rxjs, use sigi could get ride of complex flow of internal stats.`}</li>
      <li parentName="ul">{`💡 `}<strong parentName="li">{`Type safe`}</strong>{`: Sigi provides Type Safe APIs which connect the gap between your Component and Side Effect codes in compile time.`}</li>
      <li parentName="ul">{`📌 `}<strong parentName="li">{`Dependencies Injection`}</strong>{`: Sigi contains a tiny dependency injection implementation. Which allow you easier to compose your Modules and Services. And it is also provide huge benefit when you want to write some tests.`}</li>
      <li parentName="ul">{`💖 `}<strong parentName="li">{`Multi platforms support`}</strong>{`: Sigi now support React/React Native and Vue 2.x.`}</li>
    </ul>
    <h2 {...{
      "id": "packages"
    }}>{`Packages`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Project`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Status`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`@sigi/core`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img alt="badge" src="https://img.shields.io/npm/v/@sigi/core.svg?sanitize=true&style=for-the-badge" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Framework core APIs to encapsulate related effects`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`@sigi/react`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img alt="badge" src="https://img.shields.io/npm/v/@sigi/react.svg?sanitize=true&style=for-the-badge" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`React Hooks APIs`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`@sigi/vue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img alt="badge" src="https://img.shields.io/npm/v/@sigi/vue.svg?sanitize=true&style=for-the-badge" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`VueJS integration library`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`@sigi/react-router`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img alt="badge" src="https://img.shields.io/npm/v/@sigi/react-router.svg?sanitize=true&style=for-the-badge" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`React router integration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`@sigi/ssr`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img alt="badge" src="https://img.shields.io/npm/v/@sigi/ssr.svg?sanitize=true&style=for-the-badge" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Server side rendering support for `}<inlineCode parentName="td">{`Vue/React`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`@sigi/devtool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img alt="badge" src="https://img.shields.io/npm/v/@sigi/devtool.svg?sanitize=true&style=for-the-badge" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Redux devtool integration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`@sigi/di`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img alt="badge" src="https://img.shields.io/npm/v/@sigi/di.svg?sanitize=true&style=for-the-badge" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dependencies injection library which allow you compose your own class with `}<inlineCode parentName="td">{`Sigi Modules`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`@sigi/testing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img alt="badge" src="https://img.shields.io/npm/v/@sigi/testing.svg?sanitize=true&style=for-the-badge" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Test helper library`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "required-polyfill-in-legacy-browsers"
    }}>{`Required polyfill in legacy browsers`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Bundle these polyfills on demand, `}<inlineCode parentName="p">{`Sigi`}</inlineCode>{` won't import them directly.`}</p>
    </blockquote>
    <ul>
      <li parentName="ul">{`Promise`}</li>
      <li parentName="ul">{`Map/Set`}</li>
      <li parentName="ul">{`Reflect metadata`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.npmjs.com/package/@abraham/reflection"
            }}>{`@abraham/reflection`}</a>{` `}<inlineCode parentName="li">{`~3k`}</inlineCode>{` `}<strong parentName="li">{`Recommand`}</strong></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/rbuckton/reflect-metadata"
            }}>{`reflect-metadata`}</a>{` `}<inlineCode parentName="li">{`~50k`}</inlineCode></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/zloirock/core-js"
            }}>{`core-js/es7/reflect`}</a>{` `}<inlineCode parentName="li">{`~80K`}</inlineCode></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://zhuanlan.zhihu.com/p/107132560"
        }}>{`Sigi 介绍`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://join.slack.com/t/sigiframework/shared_invite/enQtOTM4ODYwMjQ5OTY5LTRmM2JhYTA1NTA5OWVkZWQzZmVkZjE4OGM0ZjM4NGJlZTliNTZjYjE5OGExMjE3MTNkNGE2NmE1MjM5NDQwNTY"
        }}>{`join slack`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      